import React from "react";

const Imgmenu = (prop) => {
    let stylePad = {
        padding : '5% 10%',
      }
      return (<div style={stylePad}>
        <img src={`${process.env.PUBLIC_URL}imagen/${prop.imagen}`} alt="" style={{width : "100%"}}/>
    </div>)
}

export default Imgmenu