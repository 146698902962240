import { toast } from "react-toastify";

const validateField = (field, nombre) => {
    let errors = ""
      if (field === '') {
        errors = `Ingrese el ${nombre}.`
      }
      if(errors!==""){error(errors)}
      return errors;
  }

  const validateRuc = ruc => {
    let errors = ""
    if (ruc === '') {
      errors = 'Ingrese el ruc'
    } else if (ruc.length < 4) {
      errors = `Debe tener un mínimo de 4 cáracteres.`
    } else if (ruc.substring(ruc.length-2,ruc.length-1)!=="-"){
      errors = 'El ruc debe contener digito verificador.'
    }
    if(errors!==""){error(errors)}
    return errors
  }
  
  const validateEmail = email => {
    let errors = ""
    const isValidEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (email === '') {
      errors = 'Por favor ingresa tu correo electrónico.'
    } else if (!isValidEmail.test(email)) {
      errors = 'Ingresa un correo electrónico válido.'
    }
    if(errors!==""){error(errors)}
    return errors
  }

  const validNroDoc = (nro) => {
    return (nro.length===4||nro.length===8)&&nro.substring(nro.length-1)!=="-"?
    `${nro.substring(0, nro.length-1)}-${nro.substring(nro.length-1)}`
    :nro
  }

  const validLargoDoc = (nro) => {
    switch (nro.length) {
      case 9:
        return `${nro.substring(0,8)}000000${nro.substring(8)}`;
      case 10:
        return `${nro.substring(0,8)}00000${nro.substring(8)}`;
      case 11:
        return `${nro.substring(0,8)}0000${nro.substring(8)}`;
      case 12:
        return `${nro.substring(0,8)}000${nro.substring(8)}`;
      case 13:
        return `${nro.substring(0,8)}00${nro.substring(8)}`;
      case 14:
        return `${nro.substring(0,8)}0${nro.substring(8)}`;
      default:
        return nro;
    }
  }

  const error= (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored"
      });
  }
  
  export  {validateField, validateEmail, validateRuc, validNroDoc, validLargoDoc} 