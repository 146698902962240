import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { validateEmail } from "../../Datos/Validacion";
import { toast } from "react-toastify";
import { consultaLote } from "../../apis/api";

const ConsultaLote = (datos) => {
  const { open, handleClose, selected } = datos;
  const [errores, setErrores] = React.useState({
    helperText: "",
    error: false,
  });

  const [nrolote, setNrolote] = React.useState("");
  useEffect(() => {
    setNrolote(selected.lote);
    setErrores({
      helperText: "",
      error: false,
    });
  }, [open]);

  const handleConfirm = async () => {
    let resp = await consultaLote(nrolote);
    console.log("resp", resp);
    if (resp !== "" && resp !== undefined) {
      // if (resp.length > 2) {
      resp =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(resp));
      let downloadNode = document.createElement("a");
      downloadNode.setAttribute("href", resp);
      downloadNode.setAttribute("download", "Consulta_Lote.json");
      document.body.appendChild(downloadNode); // required for firefox
      downloadNode.click();
      downloadNode.remove();
      handleClose();
      return toast.success(
        `Consulta de Lote ${nrolote} realizada exitosamente`,
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      // } else {
      //   toast.error(
      //     `HA VENCIDO EL PLAZO DE 48 HORAS PARA LA CONSULTA DE LOTE`,
      //     {
      //       position: "top-right",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //     }
      //   );
      // }
    } else {
      toast.error(`No fue posible consultar el lote ${nrolote}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const hangleChange = (event) => {
    setNrolote(event.target.value.trim());
  };

  const limpiarError = () => {
    setErrores({
      helperText: "",
      error: false,
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Consultar Lote</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Un lote puede contener hasta 50 documentos electrónicos.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nro de lote"
            type={"text"}
            fullWidth
            variant="standard"
            onChange={hangleChange}
            value={nrolote}
            helperText={errores.helperText}
            error={errores.error}
            onFocus={limpiarError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm}>Consultar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConsultaLote;
