import React from "react";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
const Menuitem = (prop) => {
    return (
        <MenuItem onClick={prop.handleClick} >
            <ListItemIcon>
                {prop.children}
            </ListItemIcon>
            <ListItemText>{prop.text}</ListItemText>
        </MenuItem>
    )
}

export default Menuitem