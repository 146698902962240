import React from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { validateEmail } from "../../Datos/Validacion";
import { toast } from "react-toastify";
import { enviarMail, enviarMailCanceladoManual } from "../../apis/api";

const EnvMail = (datos) => {
  const { open, handleClose, selected, configuracion } = datos;

  const [errores, setErrores] = React.useState({
    helperText: "",
    error: false,
  });

  const [correo, setCorreo] = React.useState("");
  React.useEffect(() => {
    setCorreo(selected.climail);
    setErrores({
      helperText: "",
      error: false,
    });
  }, [open]);

  const handleConfirm = async () => {
    let helperText = validateEmail(correo);
    if (helperText !== "") {
      setErrores({
        helperText: helperText,
        error: true,
      });
    } else {
      setErrores({
        helperText: "",
        error: false,
      });

      if (selected.estado === "Cancelado") {
        await enviarMailCanceladoManual(correo, selected.cdc)
          .then((res) => {
            toast.success("Correo enviado con exito", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleClose();
          })
          .catch((error) => {
            toast.error("No fue posible enviar el correo", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleClose();
          });
      } else {
        if (selected.estado === "Aprobado") {
          await enviarMail(
            selected,
            JSON.parse(sessionStorage.getItem("token")).nombre,
            correo,
            configuracion
          )
            .then((res) => {
              toast.success("Correo enviado con exito", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              handleClose();
            })
            .catch((error) => {
              toast.error("No fue posible enviar el correo", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              handleClose();
            });
        } else {
          return toast.error(
            `No se puede enviar correos si el documento está en estado ${selected.estado}`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    }
  };

  const hangleChange = (event) => {
    setCorreo(event.target.value.trim());
  };

  const limpiarError = () => {
    setErrores({
      helperText: "",
      error: false,
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Envio de documento electrónico</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Con esta opcion puede reenviar kude en formato pdf al correo que
            indique debajo.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Dirección de Email"
            type="email"
            fullWidth
            variant="standard"
            onChange={hangleChange}
            value={correo}
            helperText={errores.helperText}
            error={errores.error}
            onFocus={limpiarError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleConfirm}>Enviar Kude</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EnvMail;
