import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const VerMotivo = (datos) => {
  const { open, handleClose, selected } = datos;

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Motivo de rechazo de documento electrónico</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {"Código : " + selected.codigores}
          </DialogContentText>
          <DialogContentText>{selected.motivo}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VerMotivo;
