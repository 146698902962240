import { toast } from "react-toastify";
import { getItemSession, getSession, MainUrl } from "./Session";
import { dataDE_ } from "../Componentes/pruebas/data";
import axios from "axios";
import { South } from "@mui/icons-material";

const validUsuario = async (usuario) => {
  try {
    let response = await fetch(
      `${MainUrl}rest/ValidUsu?ruc=${usuario.ruc}&pass=${usuario.password}`
    );
    let json = { estado: false };
    if (response.status === 200) {
      json = await response.json();
    } else {
      toast("Problemas para conectarse con el servidor", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return json;
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { estado: "error" };
  }
};

const cambiaPass = async (usuario) => {
  try {
    let response = await fetch(
      `${MainUrl}rest/ValidUsu/pass?ruc=${usuario.ruc}&pass=${usuario.passwordNewConf}`
    );
    let json = { estado: false };
    if (response.status === 200) {
      json = await response.json();
    } else {
      toast("Problemas para conectarse con el servidor", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return json;
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { estado: "error" };
  }
};

const listarDocs = async (filtro) => {
  try {
    const rawResponse = await fetch(
      `${MainUrl}rest/DE/listar?token=${getSession().token}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(filtro),
      }
    );
    // let response = await rawResponse;
    let json = { estado: false };
    if (rawResponse.status === 200) {
      json = await rawResponse.json();
      console.log("json", json);
    } else {
      toast("Problemas para conectarse con el servidor", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return json;
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { estado: false };
  }
};

const imprimir = async (selected) => {
  try {
    window.open(
      `${MainUrl}printDE?ruc=${getItemSession("ruc")}&cdc=${selected}&token=${
        getSession().token
      }`
    );
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const descargarPdf = async (selected) => {
  try {
    const response = await fetch(
      `${MainUrl}rest/descargaXML/pdf?ruc=${getItemSession(
        "ruc"
      )}&codigo=${selected}`
    );
    console.log("response", response);
    if (response.status === 200) {
      const file = await response.blob();
      const url = URL.createObjectURL(
        new Blob([file], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${selected}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      URL.revokeObjectURL(url);
    }
  } catch (error) {
    toast(`Error = ${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const imprimirLs = async (selected) => {
  const token = getItemSession("token");
  try {
    window.open(
      `${MainUrl}printRptDE?ruc=${getItemSession(
        "ruc"
      )}&cdc=${selected}&token=${token}`
    );
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const enviarMail = async (
  { cdc, cliente, comprobante, fecha, moneda, total, numero },
  emisor,
  correo,
  configuracion
) => {
  const token = getItemSession("token");
  try {
    let requestOptions = {
      method: "POST",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
      data: JSON.stringify({
        mailTo: correo.replace("`", ""),
        ruc: getItemSession("ruc"),
        cdc: cdc,
      }),
      url: `https://factemail.facturafacil.com.py/enviar/mail`,
      // url: `http://localhost:8088/enviar/mail`,
    };
    const response = await axios(requestOptions);
    let json = { estado: "no" };
    if (response.status === 200) {
      json = await response.data;
      updateEstadomail(cdc);
    } else {
      toast("Problemas para conectarse con el servidor t", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return json;
  } catch (error) {
    toast(error.response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { estado: "no" };
  }
};

const updateEstadomail = async (cdc) => {
  try {
    let requestOptions = {
      method: "GET",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
      url: `${MainUrl}rest/DE/updMail?ruc=${getItemSession("ruc")}&cdc=${cdc}`,
    };
    const response = await axios(requestOptions);
    if (response.status == 200) {
      console.log(response.data);
    }
  } catch (error) {
    toast(error.response.data, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const consultaLote = async (nro) => {
  let requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
    mode: "no-cors",
    url: `${MainUrl}rest/consultarLote/json?ruc=${getItemSession(
      "ruc"
    )}&codigo=${nro.trim()}&token=${getSession().token}`,
  };
  try {
    const response = await axios(requestOptions);
    if (response.data.hasOwnProperty("excepcion")) {
      return toast.error(response.data.mensaje + response.data.excepcion);
    } else {
      if (response && response.status === 200) {
        return response.data;
      } else {
        return toast.warning(`ERROR DE CONEXION AL SERVICIO DE SIFEN`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: localStorage.getItem("__theme_color").match(/light.*/)
            ? "light"
            : "dark",
        });
      }
    }
  } catch (error) {
    return toast(`Error = ${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const consultaCDC = async (nro) => {
  try {
    let requestOptions = {
      method: "GET",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
      url: `${MainUrl}rest/consultarDE/json?ruc=${getItemSession(
        "ruc"
      )}&codigo=${nro.trim()}&token=${getSession().token}`,
    };
    const response = await axios(requestOptions);
    if (response && response.status === 200) {
      if (response.data.hasOwnProperty("excepcion")) {
        return toast.error(response.data.mensaje + response.data.excepcion);
      } else {
        return response.data;
      }
    } else {
      return toast.warning(`ERROR DE CONEXION AL SERVICIO`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: localStorage.getItem("__theme_color").match(/light.*/)
          ? "light"
          : "dark",
      });
    }
  } catch (error) {
    return toast(`Error = ${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const cancelarDoc = async (cdc, motivo) => {
  const token = getItemSession("token");
  try {
    let requestOptions = {
      method: "GET",
      mode: "no-cors",
      headers: { "Content-Type": "application/json" },
      url: `${MainUrl}rest/cancelar/json?ruc=${getItemSession(
        "ruc"
      )}&cdc=${cdc}&motivo=${motivo}&token=${token}`,
    };
    const response = await axios(requestOptions);
    if (response && response.status === 200) {
      if (response.data.hasOwnProperty("excepcion")) {
        return toast.error(response.data.mensaje + response.data.excepcion);
      } else {
        return response.data;
      }
    } else {
      return toast.warning(`ERROR DE CONEXION AL SERVICIO`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: localStorage.getItem("__theme_color").match(/light.*/)
          ? "light"
          : "dark",
      });
    }
  } catch (error) {
    return toast(`Error = ${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const enviarMailCancelado = async (data) => {
  let options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({
      mailTo: data.climail.replace("`", ""),
      ruc: getItemSession("ruc"),
      cdc: data.cdc,
    }),
    url: `https://factemail.facturafacil.com.py/enviar/mailCancelado`,
  };
  return await axios(options);
};

const enviarMailCanceladoManual = async (mail, cdc) => {
  let options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({
      mailTo: mail,
      ruc: getItemSession("ruc"),
      cdc: cdc,
    }),
    url: `https://factemail.facturafacil.com.py/enviar/mailCancelado`,
  };
  return await axios(options);
};

const invalidarDocs = async (data) => {
  const token = getItemSession("token");
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };

    let response = await fetch(
      `${MainUrl}rest/inutilizar/json?ruc=${getItemSession(
        "ruc"
      )}&token=${token}`,
      requestOptions
    );
    let json = { estado: "false" };
    if (response.status === 200) {
      json = await response.json();
    }
    return json;
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const getConfiguracion = async () => {
  try {
    const token = getItemSession("token");
    let response = await fetch(
      `${MainUrl}rest/Config/recup?ruc=${getItemSession("ruc")}&token=${token}`
    );
    let json = { estado: "false" };
    if (response.status === 200) {
      return response.json();
    } else {
      return { estado: "false" };
    }
    // return json;
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { estado: "false" };
  }
};

const getVencimiento = async () => {
  try {
    const token = getItemSession("token");
    let response = await fetch(
      `${MainUrl}rest/Config/getVencimiento?ruc=${getItemSession("ruc")}`
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return { estado: "false" };
    }
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { estado: "false" };
  }
};

async function subirCert(data, file) {
  let resp = await fetch(
    `${MainUrl}rest/Config/certificado/${getItemSession("ruc")}`,
    {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/x-pkcs12",
      },
      body: file,
    }
  )
    .then((res) => res.json())
    .then((res) => {
      return res;
    });
  if (resp.res === "si") {
    const resp = await fetch(`${MainUrl}rest/Config/validCert`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (resp.status === 200) {
      let json = await resp.json();
      toast("Certificado subido correctamente", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return json;
    }
  }
}

export const subirReporte = async (file) => {
  let response = await fetch(
    `${MainUrl}rest/CfgReportes/upload?ruc=${getItemSession("ruc")}&cmp=${
      file.name.split("_")[1]
    }&est=${file.name.split("_")[2]}&exp=${
      file.name.split("_")[3].split(".")[0]
    }`,
    {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/octet-stream",
      },
      body: file,
    }
  );
  let json = { estado: "false" };
  console.log(response);
  if (response.status === 200) {
    json = await response.json();
    console.log("json", json);
  }
  return json.mensaje;
};

const subirDatos = async (data) => {
  const resp = await fetch(`${MainUrl}rest/Config/datos`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (resp.status === 200) {
    toast.success("Configuracion Grabada Correctamente", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "si";
  } else {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "no";
  }
};

const subirLogo = async (file, sucursal, expedicion) => {
  let response = await fetch(
    `${MainUrl}rest/Config/logo?ruc=${getItemSession(
      "ruc"
    )}&suc=${sucursal}&exp=${expedicion}`,
    {
      mode: "cors",
      method: "post",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "image/png",
      },
      body: file,
    }
  );
  let json = { estado: "false" };
  if (response.status === 200) {
    json = await response.json();
  }
  return json;
};

const subirkude = async (file) => {
  let response = await fetch(`${MainUrl}Config/Kude/${getItemSession("ruc")}`, {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/x-pkcs12",
    },
    body: file,
  });
  let json = { estado: "false" };
  if (response.status === 200) {
    json = await response.json();
  }
  return json;
};

const grabaConfigPrueba = async (data) => {
  const resp = await fetch(`${MainUrl}rest/ConfigPrueba/graba`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  if (resp.status === 200) {
    // json = await resp.json();
    toast.success("Numeración Grabada Correctamente", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "si";
  } else {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return "no";
  }
};

const getConfigPrueba = async () => {
  try {
    let response = await fetch(
      `${MainUrl}rest/ConfigPrueba/rec?ruc=${getItemSession("ruc")}`
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return { estado: "false" };
    }
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { estado: "false" };
  }
};

const getDocsPrueba = async () => {
  try {
    let response = await fetch(
      `${MainUrl}rest/ConfigPrueba/recDE?ruc=${getItemSession("ruc")}`
    );
    if (response.status === 200) {
      return response.json();
    } else {
      return { estado: "false" };
    }
  } catch (error) {
    toast("Problemas para conectarse con el servidor", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    return { estado: "false" };
  }
};

export {
  validUsuario,
  listarDocs,
  imprimir,
  enviarMail,
  cancelarDoc,
  enviarMailCancelado,
  enviarMailCanceladoManual,
  invalidarDocs,
  getConfiguracion,
  getVencimiento,
  subirCert,
  subirDatos,
  subirLogo,
  subirkude,
  consultaLote,
  consultaCDC,
  cambiaPass,
  grabaConfigPrueba,
  getConfigPrueba,
  getDocsPrueba,
  descargarPdf,
};
