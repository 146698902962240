import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Ruteo from "./Componentes/login/Ruteo";
import "./css/App.css";
import { SessionProvider } from "./hook/SessionContext";

function App() {
  return (
    <SessionProvider>
      <div className="App">
        <Ruteo />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </SessionProvider>
  );
}

export default App;
