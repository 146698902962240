import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Divider, Select, MenuItem, TextField } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import "../../fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf";
import {
  validateField,
  validateRuc,
  validNroDoc,
  validLargoDoc,
} from "../../Datos/Validacion.jsx";
import "../../css/estilo.css";
import UsrInput from "../login/UsrInput";
import PassInput from "../login/PassInput";
import { toast } from "react-toastify";
import { invalidarDocs, validUsuario, getConfiguracion } from "../../apis/api";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const ConfMail = (datos) => {
  const { open, handleClose, configuracion, handleChange } = datos;

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    ruc: "",
    motivo: "",
    desdeDoc: "",
    hastaDoc: "",
    comprobante: "Factura electrónica",
  });

  React.useEffect(() => {
    // limpiar();
  }, [open]);

  const [errores, setErrores] = React.useState({
    password: "",
    ruc: "",
    motivo: "",
    desdeDoc: "",
    hastaDoc: "",
  });

  const style = {
    width: "50%",
    // height: "calc(100vh)",
  };

  // const handleChange =
  //   (prop) =>
  //   ({ target }) => {
  //     if (prop === "desdeDoc" || prop === "hastaDoc") {
  //       if (target.value.length < 15) {
  //         setValues({ ...values, [prop]: validNroDoc(target.value) });
  //       }
  //     } else {
  //       setValues({ ...values, [prop]: target.value });
  //     }
  //   };

  // const handleClickShowPassword = () => {
  //   setValues({
  //     ...values,
  //     showPassword: !values.showPassword,
  //   });
  // };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  // const limpiar = () => {
  //   setErrores({
  //     password: "",
  //     ruc: "",
  //     motivo: "",
  //     desdeDoc: "",
  //     hastaDoc: "",
  //   });
  //   setValues({
  //     password: "",
  //     showPassword: false,
  //     ruc: "",
  //     motivo: "",
  //     desdeDoc: "",
  //     hastaDoc: "",
  //     comprobante: "Factura electrónica",
  //   });
  // };

  // const funBlur = ({ target }) => {
  //   if (values[target.name].length < 15 && values[target.name].length > 8) {
  //     setValues({
  //       ...values,
  //       [target.name]: validLargoDoc(values[target.name]),
  //     });
  //   } else if (values[target.name].length < 8) {
  //     toast.error("El ducumento debe tener 15 caracteres", {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //   }
  // };

  // const handleClick = async () => {
  //   setErrores({
  //     password: validateField(values.password, "password"),
  //     ruc: validateRuc(values.ruc),
  //     motivo: validateField(values.motivo, "motivo"),
  //     desdeDoc: validateField(values.desdeDoc, "desde doc."),
  //     hastaDoc: validateField(values.hastaDoc, "hasta doc."),
  //   });
  //   if (
  //     errores.password === "" &&
  //     errores.ruc === "" &&
  //     errores.motivo === "" &&
  //     errores.desdeDoc === "" &&
  //     errores.hastaDoc === ""
  //   ) {
  //     let resp = await validUsuario(values);
  //     if (resp.estado === false) {
  //       toast.error("Ruc o contraseña incorrectos", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //       });
  //     } else {
  //       let config = await getConfiguracion(); /////me falta toda la configuracion
  //       let data = {
  //         dNumTim: 12345678,
  //         dEst: values.desdeDoc.substring(0, 3),
  //         dPunExp: values.hastaDoc.substring(4, 7),
  //         dNumIn: values.desdeDoc.substring(8),
  //         dNumFin: values.hastaDoc.substring(8),
  //         iTiDE: values.comprobante,
  //         mOtEve: values.motivo,
  //       };

  //       let json = await invalidarDocs(data);
  //       if (json.estado === "Aprobado") {
  //         if (json.error === "") {
  //           toast.success("Documentos inutilizados con exito", {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });
  //           handleClose();
  //         } else {
  //           toast.error(json.error, {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //           });
  //         }
  //       } else {
  //         toast.error(`Inutilizacion ${json.estado} por ${json.mensaje}`, {
  //           position: "top-right",
  //           autoClose: 5000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //         });
  //       }
  //     }
  //   }
  // };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Configuracion correo electrónico</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Con esta opción se configuran los parametros para el envío de correo
            electrónico a los clientes
          </DialogContentText>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="mailhost"
                  name="mailhost"
                  label="mail.smtp.host"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onBlur={handleChange}
                  onLoad={handleChange}
                  value={configuracion.mailhost}
                  fullWidth
                />
                {/* <UsrInput
                  name={"mailhost"}
                  // handleChange={handleChange("desdeDoc")}
                  // value={values.desdeDoc}
                  type={"text"}
                  label="mail.smtp.host"
                  // helperText={errores.desdeDoc}
                  // error={errores.desdeDoc === "" ? false : true}
                  // funBlur={funBlur}
                >
                  <DocumentScannerOutlinedIcon />
                </UsrInput> */}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="mailport"
                  name="mailport"
                  label="mail.smtp.port"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                  value={configuracion.mailport}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="mailport2"
                  name="mailport2"
                  label="mail.smtp.socketFactory.port"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                  value={configuracion.mailport2}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <InputLabel id="demo-simple-select-standard-label">
                  mail.transport.protocol
                </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="protocol"
                  name="protocol"
                  style={style}
                  // defaultValue={"smtp"}
                  value={configuracion.protocol}
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                >
                  <MenuItem value="smtp">SMTP</MenuItem>
                  <MenuItem value="smtps">SMTPS</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel id="demo-simple-select-standard-label">
                  mail.smtp.socketFactory.fallback
                </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="fallback"
                  name="fallback"
                  style={style}
                  // defaultValue={"false"}
                  value={configuracion.fallback}
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel id="demo-simple-select-standard-label">
                  mail.smtp.starttls.required
                </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="starttls"
                  name="starttls"
                  style={style}
                  // defaultValue={"true"}
                  value={configuracion.starttls}
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <InputLabel id="demo-simple-select-standard-label">
                  mail.smtp.ssl
                </InputLabel>
              </Grid>
              <Grid item xs={6}>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="ssl"
                  name="ssl"
                  style={style}
                  // defaultValue={"true"}
                  value={configuracion.ssl}
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                >
                  <MenuItem value="true">True</MenuItem>
                  <MenuItem value="false">False</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="mail"
                  name="mail"
                  label="Correo Emisor"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                  value={configuracion.mail}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="mailpass"
                  name="mailpass"
                  label="Contraseña"
                  type="password"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  onLoad={handleChange}
                  onBlur={handleChange}
                  value={configuracion.mailpass}
                  fullWidth
                />
              </Grid>
              <Divider variant="middle" />
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>volver</Button>
          {/* <Button onClick={handleClick}>Cancelar documento</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfMail;
