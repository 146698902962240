import React, { createContext, useState } from "react";
import { getItemSession } from "../apis/Session";

export const SessionContext = createContext({
  token: "",
  updSession: () => {},
});

export const SessionProvider = ({ children }) => {
  const [token, setToken] = useState(getItemSession("token"));
  const updSession = (tkn) => {
    setToken(tkn);
  };
  return (
    <SessionContext.Provider value={{ token, updSession }}>
      {children}
    </SessionContext.Provider>
  );
};
