import React from "react";
import { Button , Grid } from "@mui/material";
import UsrInput from "./UsrInput";
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { validateEmail, validateRuc } from "../../Datos/Validacion";
import { toast } from "react-toastify";
import '../../css/estilo.css';
import 'react-toastify/dist/ReactToastify.css';

const Recupera = ({handleClickRecupera, padding2}) => {

    const [values, setValues] = React.useState({
        mail: '',
        ruc: '',
        errorRuc: '',
        errorMail: '',
        visibleMsg: false,
    });

    const [exito, setExito] = React.useState(false);
    

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value.trim() });
    };



    const handleClick = () => {
        let valError = false
        if(validateRuc(values.ruc)!==""){
            valError = true
            toast.error(validateRuc(values.ruc), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        if(validateEmail(values.mail)!==""){
            valError = true
            toast.error(validateEmail(values.mail), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
        }
        if(!valError){
            toast.success('Correo enviado con exito', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                });
                setExito(true);
        }
    }

    return(
        <div style={padding2}>
            <form id="formRecupera">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <UsrInput 
                            handleChange={handleChange('ruc')} 
                            value={values.ruc}
                            type={"text"}
                            label="R.U.C."
                            helperText={values.errorRuc}
                            error={values.errorRuc!==""}>
                                <PersonOutlinedIcon/>
                            </UsrInput>
                    </Grid>
                    <Grid item xs={12}>
                        <UsrInput 
                            handleChange={handleChange('mail')} 
                            value={values.mail}
                            label="Mail"
                            type={"email"}
                            helperText={values.errorMail}
                            error={values.errorMail!==""}
                            >
                                <EmailOutlinedIcon/>
                            </UsrInput>
                    </Grid>
                    <Grid item xs={exito?12:6} style={{textAlign:'center'}}>
                       <Button onClick={handleClickRecupera} variant="outlined" color="primary" startIcon={<ArrowBackOutlinedIcon />} className="button w-90">Volver</Button>
                    </Grid>
                    <Grid item xs={6} style={exito?{display:'none' , textAlign:'center'}:{textAlign:'center'}}>
                       <Button variant="contained" color="primary" endIcon={<ArrowForwardIcon />} className="button w-90" onClick={handleClick}>Recuperar</Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default Recupera