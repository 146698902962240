import React from "react";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Imgmenu from "./Imgmenu";
import "../../../css/menu.css";
import Menulist from "./Menulist";

const Menu = () => {
  return (
    <Paper sx={{ width: "17%", maxWidth: "100%" }} className="menu">
      <Imgmenu imagen="TF.png" />
      <Divider />
      <Menulist />
      <Divider />
      <Imgmenu imagen="logo_starsoft.png" />
    </Paper>
  );
};

export default Menu;
