import React, { useEffect } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { validateEmail } from "../../Datos/Validacion";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { toast } from "react-toastify";
import { consultaCDC } from "../../apis/api";

const ConsultaDE = (datos) => {
  const { open, handleClose } = datos;

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <Button></Button>
        <Box sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </Dialog>
    </div>
  );
};

export default ConsultaDE;
