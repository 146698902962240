import React, { useEffect, useState } from "react";
import Menu from "../principal/menu/Menu";
import { getSession, MainUrl } from "../../apis/Session";
import moment from "moment";
import {
  Box,
  Grid,
  Button,
  Paper,
  TextField,
  FormControl,
  Select,
  Container,
  Typography,
  InputAdornment,
  MenuItem,
  InputLabel,
} from "@mui/material";

const Reportes = () => {
  const style = {
    marginLeft: "20%",
    width: "40%",
    // width: "80%",
    height: "calc(88vh)",
  };
  const paper = {
    width: "100%",
    height: "calc(88vh)",
  };
  const [filtro, setFiltro] = useState({
    token: getSession().token,
    ruc: getSession().ruc,
    cdc: "",
    lote: "",
    fecha: "",
    fecini: moment().format("YYYY-MM-DD"),
    fecfin: moment().format("YYYY-MM-DD"),
    envmail: "",
    nombre: "",
    suc: "",
    exp: "",
    nrocmp: "",
    tipcmp: "",
    condicion: "",
    limit: 0,
    offset: 0,
    orden: "fechaRep",
    tipOrden: "desc",
  });

  const handleChange = (event) => {
    switch (event.target.name) {
      case "tipcmp":
        if (event.target.value === "Todos") {
          setFiltro({ ...filtro, [event.target.name]: "" });
        } else {
          setFiltro({ ...filtro, [event.target.name]: event.target.value });
        }
        break;
      case "condicion":
        if (event.target.value === "Todos") {
          setFiltro({ ...filtro, [event.target.name]: "" });
        } else {
          setFiltro({ ...filtro, [event.target.name]: event.target.value });
        }
        break;
      case "envmail":
        if (event.target.value === "Todos") {
          setFiltro({ ...filtro, [event.target.name]: "" });
        } else {
          setFiltro({ ...filtro, [event.target.name]: event.target.value });
        }
        break;
      default:
        setFiltro({ ...filtro, [event.target.name]: event.target.value });
        break;
    }
  };

  function openWindowWithPost(url, data, open) {
    console.log("data", data);
    let form = document.createElement("form");
    form.target = open;
    form.method = "POST";
    form.action = url;
    form.style.display = "none";

    for (let key in data) {
      let input = document.createElement("input");
      input.type = "hidden";
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }
    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  useEffect(() => {
    setFiltro({
      ...filtro,
      token: getSession().token,
      ruc: getSession().ruc,
      cdc: "",
      lote: "",
      fecha: "",
      fecini: moment().format("YYYY-MM-DD"),
      fecfin: moment().format("YYYY-MM-DD"),
      envmail: "",
      nombre: "",
      suc: "",
      exp: "",
      nrocmp: "",
      tipcmp: "",
      condicion: "",
      limit: 0,
      offset: 0,
      orden: "fechaRep",
      tipOrden: "desc",
    });
  }, []);

  return (
    <React.Fragment>
      <div>
        <Box sx={{ display: "flex" }} overflow="hidden">
          <Menu />
          <div style={style}>
            <h1 style={{ textAlign: "right", color: "rgb(212 212 219)" }}>
              Reportes
            </h1>
            <Paper sx={{ width: "100%", maxWidth: "100%" }} className="header">
              <Grid container>
                <Grid item xs={12}>
                  <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
                    <Paper
                      variant="outlined"
                      sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
                    >
                      <Typography
                        component="h1"
                        variant="h4"
                        align="center"
                        style={{ marginBottom: "30px" }}
                      >
                        D.E. Emitidos
                      </Typography>
                      <React.Fragment>
                        <Grid container>
                          <Grid
                            item
                            xs={7}
                            style={{ marginBottom: "calc(2vh)" }}
                          >
                            <TextField
                              fullWidth
                              label="Desde Fecha"
                              id="fecini"
                              name="fecini"
                              value={filtro.fecini}
                              type="date"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start"></InputAdornment>
                                ),
                              }}
                              variant="standard"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            style={{ marginBottom: "calc(1vh)" }}
                          >
                            <TextField
                              fullWidth
                              label="Hasta Fecha"
                              id="fecfin"
                              name="fecfin"
                              value={filtro.fecfin}
                              type="date"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start"></InputAdornment>
                                ),
                              }}
                              variant="standard"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Tipo Documento
                              </InputLabel>
                              <Select
                                id="tipcmp"
                                name="tipcmp"
                                value={filtro.tipCmp}
                                onChange={handleChange}
                              >
                                <MenuItem value="Todos">Todos</MenuItem>
                                <MenuItem value="Factura electrónica">
                                  Factura
                                </MenuItem>
                                <MenuItem value="Nota de crédito electrónica">
                                  Nota de crédito
                                </MenuItem>
                                <MenuItem value="Autofactura electrónica">
                                  Autofactura
                                </MenuItem>
                                <MenuItem value="Nota de débito electrónica">
                                  Nota de débito
                                </MenuItem>
                                <MenuItem value="Nota de remisión electrónica">
                                  Nota de remisión
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              fullWidth
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Estado
                              </InputLabel>
                              <Select
                                id="condicion"
                                name="condicion"
                                value={
                                  filtro.condicion === ""
                                    ? "Todos"
                                    : filtro.condicion
                                }
                                onChange={handleChange}
                              >
                                <MenuItem value="Todos">Todos</MenuItem>
                                <MenuItem value="Pendiente">
                                  Pendientes
                                </MenuItem>
                                <MenuItem value="Aprobado">Aprobados</MenuItem>
                                <MenuItem value="Rechazado">
                                  Rechazados
                                </MenuItem>
                                <MenuItem value="Cancelado">
                                  Cancelados
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {/* <Grid item xs={12} sm={6}>
                            <FormControl
                              fullWidth
                              variant="standard"
                              sx={{ m: 1, minWidth: 120 }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Notificados
                              </InputLabel>
                              <Select
                                id="envmail"
                                name="envmail"
                                value={filtro.envMail}
                                onChange={handleChange}
                              >
                                <MenuItem value="Todos">Todos</MenuItem>
                                <MenuItem value="SI">SI</MenuItem>
                                <MenuItem value="NO">NO</MenuItem>
                              </Select>
                            </FormControl>
                          </Grid> */}

                          <Grid item xs={12}>
                            <React.Fragment>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {/* <Button
                                  variant="contained"
                                  color="success"
                                  onClick={(e) =>
                                    openWindowWithPost(
                                      `${MainUrl}getRptExcel`,
                                      filtro,
                                      ""
                                    )
                                  }
                                  sx={{ mt: 3, ml: 1 }}
                                >
                                  Descargar Excel
                                </Button> */}
                                <Button
                                  variant="contained"
                                  onClick={(e) =>
                                    openWindowWithPost(
                                      `${MainUrl}printRptDE`,
                                      filtro,
                                      "_blank"
                                    )
                                  }
                                  sx={{ mt: 3, ml: 1 }}
                                >
                                  Imprimir PDF
                                </Button>
                              </Box>
                            </React.Fragment>
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    </Paper>
                  </Container>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default Reportes;
