import React, { useContext } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button, Grid } from "@mui/material";
import { toast } from "react-toastify";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import "../../fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf";
import { validateRuc, validateField } from "../../Datos/Validacion";
import "../../css/estilo.css";
import UsrInput from "./UsrInput";
import PassInput from "./PassInput";
import { validUsuario, getConfiguracion } from "../../apis/api";
import { useNavigate } from "react-router-dom";
import { setSession } from "../../apis/Session";
import { SessionContext } from "../../hook/SessionContext";

const Formulario = ({ handleClickRecupera, padding2 }) => {
  const navigate = useNavigate();
  const { updSession } = useContext(SessionContext);

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    ruc: "",
  });

  const [errores, setErrores] = React.useState({
    password: "",
    ruc: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = async () => {
    const error = {
      ruc: validateRuc(values.ruc),
      password: validateField(values.password, "password"),
    };
    setErrores(error);
    if (error.ruc === "" && error.password === "") {
      let resp = await validUsuario(values);
      if (resp.estado === false) {
        toast.error("Ruc o contraseña incorrectos", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (resp.estado === true) {
          setSession(resp);
          updSession(resp.token);
          let config = await getConfiguracion();
          if (config !== "" && config !== undefined && config !== null) {
            localStorage.setItem("server", JSON.stringify(config.tipserv));
            setTimeout(() => {
              navigate("/menu");
            }, 100);
          }
        } else {
          toast.error("Cliente bloqueado por Administración", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    }
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 13) {
      handleClick();
    }
  };

  return (
    <div style={padding2}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <UsrInput
              handleChange={handleChange("ruc")}
              value={values.ruc}
              type={"text"}
              label="R.U.C."
              helperText={errores.ruc}
              error={errores.ruc === "" ? false : true}
            >
              <PersonOutlinedIcon />
            </UsrInput>
          </Grid>
          <Grid item xs={12}>
            <PassInput
              handleChange={handleChange}
              values={values}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              helperText={errores.password}
              error={errores.password === "" ? false : true}
              keyUp={handleKeyUp}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <label
              onClick={handleClickRecupera}
              className="MuiLink-root MuiLink-button cursorPointer"
            >
              {"Olvidaste tu password"}{" "}
            </label>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              className="button"
              onClick={handleClick}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Formulario;
