import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import { getSession, MainUrl } from "../../apis/Session";
import ChangePass from "./ChangePass";
import ConfMail from "./ConfMail";
import { subirCert, subirReporte } from "../../apis/api";
import { tipoDoc, establecimiento, expedicion } from "../../apis/values";
import { getItemSession } from "../../apis/Session";

const PanelConfig = (props) => {
  const {
    configuracion,
    setConfiguracion,
    imagenlogo,
    setImagenlogo,
    ChangeImgFile,
    grabarConfiguracion,
    handleChange,
    setInputCert,
    inputcert,
    handleChangeUbi,
    handleChangeSuc,
    sucs,
    exps,
  } = props;

  const stylePad = {
    boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 20%)",
    borderRadius: "10px",
    backgroundColor: "#fff",
  };

  const styleM = {
    marginBottom: "10px",
  };

  const styleS = {
    margin: "5px",
  };

  const [tiporpt, setTiporpt] = useState({
    cmp: "FC",
    est: "001",
    exp: "001",
  });

  const handleChangeInput = async (evt) => {
    let file = evt.target.files[0];
    if (
      configuracion.passCert === "" ||
      configuracion.passCert === undefined ||
      configuracion.passCert === null
    ) {
      toast("DEBE CARGAR LA CONTRASEÑA", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      let res = await subirCert(configuracion, file);
      setConfiguracion({
        ...configuracion,
        aliasCert: res.alias,
        usuCert: res.name,
        vencimiento: res.vencimiento,
      });
    }
  };

  const btnfile = () => {
    const file = document.querySelector("#certificado");
    file.value = "";
  };

  const btnfileRpt = () => {
    const file = document.querySelector("#subirReporte");
    file.value = "";
  };

  const handleChangeInputRpt = async (evt) => {
    let file = evt.target.files[0];
    if (
      file.name.endsWith(".jrxml") &&
      file.name.split("_")[0] === getItemSession("ruc")
    ) {
      let resp = await subirReporte(file);
      toast(resp, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    } else {
      toast(
        "El archivo debe ser .jrxml y el nombre del archivo debe coincidir con el ruc de la empresa",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        }
      );
    }
  };

  const [openCnfMail, setOpenCnfMail] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleChangePass = () => {
    setOpen(true);
  };
  const handleMail = () => {
    setOpenCnfMail(true);
  };

  const handleCloseCnf = () => {
    setOpenCnfMail(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const showRtpBtn = () => {
    document.getElementById("rpt").style.display = "block";
  };

  window.addEventListener("keydown", function (e) {
    if (e.key === "F9") {
      showRtpBtn();
    }
  });

  const handleDownloadRpt = () => {
    const tagA = document.createElement("a");
    const uri = `${MainUrl}rest/CfgReportes/download?ruc=${getItemSession(
      "ruc"
    )}&cmp=${tiporpt.cmp}&est=${tiporpt.est}&exp=${tiporpt.exp}`;
    tagA.href = uri;
    tagA.click();
  };

  const handleUploadRpt = () => {};

  useLayoutEffect(() => {
    setInputCert(document.getElementById("certificado"));
  }, [inputcert]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ ...stylePad, textAlign: "left" }}>
            <Grid container>
              <Grid item xs={6}>
                <h3 style={{ marginTop: "0" }}>R.U.C.: {getSession().ruc}</h3>
                <h3 style={{ marginBottom: "5px" }}>
                  Razón Social: {getSession().nombre}
                </h3>
                <Divider style={styleM} />
                <div>
                  <TextField
                    id="timbrado"
                    // type="number"
                    name="timbrado"
                    label="Timbrado"
                    variant="outlined"
                    size="small"
                    style={styleM}
                    onChange={handleChange}
                    value={configuracion.timbrado}
                  />
                </div>
                <TextField
                  id="idCsc"
                  name="idCsc"
                  label="IdCSC"
                  variant="outlined"
                  size="small"
                  style={styleM}
                  onChange={handleChange}
                  value={configuracion.idCsc}
                />
                <TextField
                  id="csc"
                  name="csc"
                  label="CSC"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={handleChange}
                  value={configuracion.csc}
                />
                <input
                  hidden={true}
                  id="kude"
                  name="kude"
                  label="kude"
                  variant="outlined"
                  size="small"
                  style={styleM}
                  readOnly
                  value={configuracion.kude}
                />
              </Grid>
              <Grid item xs={6}>
                <Card style={{ marginLeft: "15%" }}>
                  <CardContent>
                    <Grid container>
                      <Grid>
                        <Typography gutterBottom variant="h5">
                          Logo de la Empresa
                        </Typography>
                      </Grid>
                      <Grid className="gridBorder">
                        <div className="cajaImg">
                          <div className="boxImg">
                            <img
                              id="imgLogo"
                              name="imgLogo"
                              value={configuracion.logo}
                              src={
                                configuracion.logo === ""
                                  ? "#"
                                  : `${MainUrl}index.logo?ruc=${
                                      getSession().ruc
                                    }`
                              }
                              alt="Esperando imagen..."
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid container>
                      <Grid item lg={3} md={3} xs={12} mb={1}>
                        <FormControl sx={{ width: "90%" }} size="small">
                          <InputLabel id="suc001">Sucursal</InputLabel>
                          <Select
                            className="form-control form-control-sm"
                            labelId="demo-select-small"
                            id="suc001"
                            name="sucursal"
                            label="Sucursal"
                            defaultValue={sucs}
                            onChange={handleChangeSuc}
                          >
                            {establecimiento.map((item) => (
                              <MenuItem key={item.key} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={3} md={3} xs={12} mb={1}>
                        <FormControl sx={{ width: "90%" }} size="small">
                          <InputLabel id="expe001">Punto Expedición</InputLabel>
                          <Select
                            className="form-control form-control-sm"
                            labelId="demo-select-small"
                            id="expedicion"
                            name="expedicion"
                            label="Punto Expedición"
                            defaultValue={exps}
                            onChange={handleChangeUbi}
                          >
                            {expedicion.map((item) => (
                              <MenuItem key={item.key} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <input
                        hidden={true}
                        accept="image/*"
                        value=""
                        id="001"
                        name="001"
                        type="file"
                        onChange={ChangeImgFile}
                      />
                      <Grid item lg={3} md={3} xs={12} mb={1}>
                        <label htmlFor="001">
                          <Button
                            variant="contained"
                            component="span"
                            size="small"
                            color="success"
                          >
                            Adjuntar Logo
                          </Button>
                        </label>
                      </Grid>
                      <Grid item lg={3} md={3} xs={12} mb={1}>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={() => {
                            setConfiguracion({
                              ...configuracion,
                              logo: "",
                            });
                            setImagenlogo("");
                          }}
                        >
                          Remover Logo
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <h3 style={{ marginBottom: "5px" }}>Certificado</h3>
                <Divider style={styleM} />
                <div>
                  <TextField
                    id="passCert"
                    name="passCert"
                    label="Contraseña"
                    type="password"
                    variant="outlined"
                    size="small"
                    style={styleM}
                    onChange={handleChange}
                    value={configuracion.passCert}
                  />
                </div>
                <Grid item xs={6}>
                  <TextField
                    id="usuCert"
                    name="usuCert"
                    label="Usuario"
                    variant="outlined"
                    size="small"
                    style={styleM}
                    fullWidth
                    onChange={handleChange}
                    value={configuracion.usuCert}
                    disabled
                  />
                  <TextField
                    id="aliasCert"
                    name="aliasCert"
                    label="Alias"
                    variant="outlined"
                    size="small"
                    style={styleM}
                    fullWidth
                    onChange={handleChange}
                    value={configuracion.aliasCert}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="vencimiento"
                    label="Vencimiento"
                    name="vencimiento"
                    size="small"
                    variant="outlined"
                    style={styleM}
                    type="date"
                    fullWidth
                    onChange={handleChange}
                    value={
                      configuracion.vencimiento === null ||
                      configuracion.vencimiento === undefined
                        ? "1900-01-01"
                        : configuracion.vencimiento
                    }
                    disabled
                  />
                </Grid>
                <Card>
                  <CardActions lg={{ textAlign: "right" }}>
                    {configuracion.certificado === "si" ? (
                      <div>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          onClick={() => {
                            setConfiguracion({
                              ...configuracion,
                              certificado: "no",
                            });
                          }}
                        >
                          Remover Certificado
                        </Button>
                        <label style={{ marginLeft: 10, color: "gray" }}>
                          CERTIFICADO ADJUNTADO
                        </label>
                      </div>
                    ) : (
                      <div>
                        <input
                          accept=".p12"
                          style={{ display: "none" }}
                          id="certificado"
                          type="file"
                          onChange={handleChangeInput}
                          onClick={() => btnfile()}
                        />
                        <label htmlFor="certificado">
                          <Button
                            variant="contained"
                            component="span"
                            size="small"
                            color="success"
                          >
                            Adjuntar Certificado
                          </Button>
                        </label>
                      </div>
                    )}
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <div style={{ marginLeft: "15%" }}>
                  <Divider style={styleM} />
                  <Button
                    variant="contained"
                    component="span"
                    size="small"
                    color="info"
                    type="button"
                    onClick={handleMail}
                  >
                    Configurar Correo Electronico
                  </Button>
                  <h3 style={{ marginBottom: "5px" }}>Estado del Servicio</h3>
                  <Divider style={styleM} />
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel id="server">Servidor</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="server"
                      name="server"
                      value={configuracion.server}
                      label="Servidor"
                      onChange={handleChange}
                    >
                      <MenuItem value="https://ekuatia.set.gov.py/consultas-test/qr?nVersion=">
                        SERVIDOR DE PRUEBAS
                      </MenuItem>
                      <MenuItem value="https://ekuatia.set.gov.py/consultas/qr?nVersion=">
                        SERVIDOR DE PRODUCCION
                      </MenuItem>
                    </Select>
                  </FormControl>
                  <div id="rpt" style={{ display: "none" }}>
                    <h3 style={{ marginBottom: "5px" }}>Configurar reportes</h3>
                    <Divider style={styleM} />
                    <Grid container>
                      <Grid item xs={4}>
                        <FormControl
                          sx={{ m: 1, minWidth: 120 }}
                          size="small"
                          fullWidth
                        >
                          <InputLabel id="comprobante">Comprobante</InputLabel>
                          <Select
                            id="comprobante"
                            name="comprobante"
                            value={tiporpt.cmp}
                            label="Comprobante"
                            onChange={(e) =>
                              setTiporpt({ ...tiporpt, cmp: e.target.value })
                            }
                            style={styleS}
                          >
                            {tipoDoc.map((item) => (
                              <MenuItem key={item.key} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl
                          sx={{ m: 1, minWidth: 120 }}
                          size="small"
                          fullWidth
                        >
                          <InputLabel id="establecimiento">
                            Establecimiento
                          </InputLabel>
                          <Select
                            id="establecimiento"
                            name="establecimiento"
                            value={tiporpt.est}
                            label="Establecimiento"
                            onChange={(e) =>
                              setTiporpt({ ...tiporpt, est: e.target.value })
                            }
                            style={styleS}
                          >
                            {establecimiento.map((item) => (
                              <MenuItem key={item.key} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl
                          sx={{ m: 1, minWidth: 120 }}
                          size="small"
                          fullWidth
                        >
                          <InputLabel id="expedicion">
                            Punto Expedición
                          </InputLabel>
                          <Select
                            id="expedicion"
                            name="expedicion"
                            value={tiporpt.exp}
                            label="Punto Expedición"
                            onChange={(e) =>
                              setTiporpt({ ...tiporpt, exp: e.target.value })
                            }
                            style={styleS}
                          >
                            {expedicion.map((item) => (
                              <MenuItem key={item.key} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          variant="contained"
                          component="span"
                          size="small"
                          color="info"
                          type="button"
                          fullWidth
                          onClick={handleDownloadRpt}
                        >
                          Descargar
                        </Button>
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={3}>
                        <input
                          accept=".jrxml"
                          style={{ display: "none" }}
                          id="subirReporte"
                          type="file"
                          onChange={handleChangeInputRpt}
                          onClick={() => btnfileRpt()}
                        />
                        <label htmlFor="subirReporte">
                          <Button
                            variant="contained"
                            component="span"
                            size="small"
                            color="success"
                            type="button"
                            fullWidth
                            onClick={handleUploadRpt}
                          >
                            Subir
                          </Button>
                        </label>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  component="span"
                  size="small"
                  color="info"
                  type="button"
                  onClick={handleChangePass}
                >
                  Cambiar Contraseña de Sesión
                </Button>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <ChangePass open={open} handleClose={handleClose} />
      <ConfMail
        open={openCnfMail}
        handleClose={handleCloseCnf}
        configuracion={configuracion}
        handleChange={handleChange}
      />
    </React.Fragment>
  );
};

export default PanelConfig;
