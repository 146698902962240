import React from "react";

function Logo(){
    let stylePad = {
        padding : '2% 20%'
      }
    return(
        <div style={stylePad}>
            <img src={process.env.PUBLIC_URL + 'imagen/TF_horizontal.png'} alt="" style={{width : "100%"}}/>
        </div>
    )
}

export default Logo