import React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";

const headCells = [
  {
    id: "comprobante",
    numeric: false,
    disablePadding: false,
    label: "Comprobante",
  },
  {
    id: "fecha",
    numeric: false,
    disablePadding: false,
    label: "Fecha",
  },
  {
    id: "cliente",
    numeric: false,
    disablePadding: false,
    label: "Cliente",
  },
  {
    id: "numero",
    numeric: false,
    disablePadding: false,
    label: "Numero",
  },
  {
    id: "moneda",
    numeric: false,
    disablePadding: false,
    label: "Moneda",
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Total",
  },
  {
    id: "estado",
    numeric: false,
    disablePadding: false,
    label: "Estado",
  },
  {
    id: "notificado",
    numeric: false,
    disablePadding: false,
    label: "Notificado",
  },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort, loading } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <IconButton>
            <CheckOutlinedIcon />
          </IconButton>
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={
                headCell.id === "notificado"
                  ? null
                  : createSortHandler(headCell.id)
              }
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell width={"100%"} style={{ padding: "0px" }} colSpan={8}>
          {loading ? <LinearProgress style={{ width: "2236%" }} /> : null}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;
