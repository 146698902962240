import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Divider, Select, MenuItem } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import "../../fonts/Open_Sans/static/OpenSans/OpenSans-BoldItalic.ttf";
import {
  validateField,
  validateRuc,
  validNroDoc,
  validLargoDoc,
} from "../../Datos/Validacion.jsx";
import "../../css/estilo.css";
import UsrInput from "../login/UsrInput";
import PassInput from "../login/PassInput";
import { toast } from "react-toastify";
import { invalidarDocs, validUsuario, getConfiguracion } from "../../apis/api";
import LiveHelpOutlinedIcon from "@mui/icons-material/LiveHelpOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const InutilizarDoc = (datos) => {
  const { open, handleClose } = datos;
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
    ruc: "",
    motivo: "",
    desdeDoc: "",
    hastaDoc: "",
    comprobante: "Factura electrónica",
  });

  React.useEffect(() => {
    limpiar();
  }, [open]);

  // React.useEffect(async () => {
  //   config = await getConfiguracion();
  // }, []);

  const [errores, setErrores] = React.useState({
    password: "",
    ruc: "",
    motivo: "",
    desdeDoc: "",
    hastaDoc: "",
  });

  const handleChange =
    (prop) =>
    ({ target }) => {
      if (prop === "desdeDoc" || prop === "hastaDoc") {
        if (target.value.length <= 15) {
          setValues({ ...values, [prop]: validNroDoc(target.value) });
        }
      } else {
        setValues({ ...values, [prop]: target.value });
      }
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const limpiar = () => {
    setErrores({
      password: "",
      ruc: "",
      motivo: "",
      desdeDoc: "",
      hastaDoc: "",
    });
    setValues({
      password: "",
      showPassword: false,
      ruc: "",
      motivo: "",
      desdeDoc: "",
      hastaDoc: "",
      comprobante: "1",
    });
  };
  const funBlur = ({ target }) => {
    if (values[target.name].length <= 15 && values[target.name].length > 8) {
      setValues({
        ...values,
        [target.name]: validLargoDoc(values[target.name]),
      });
    } else if (values[target.name].length < 8) {
      toast.error("El documento debe tener 15 caracteres", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleClick = async () => {
    let config = await getConfiguracion(); /////me falta toda la configuracion
    setErrores({
      password: validateField(values.password, "password"),
      ruc: validateRuc(values.ruc),
      motivo: validateField(values.motivo, "motivo"),
      desdeDoc: validateField(values.desdeDoc, "desde doc."),
      hastaDoc: validateField(values.hastaDoc, "hasta doc."),
    });
    if (
      errores.password === "" &&
      errores.ruc === "" &&
      errores.motivo === "" &&
      errores.desdeDoc === "" &&
      errores.hastaDoc === ""
    ) {
      let resp = await validUsuario(values);
      if (resp.estado === false) {
        toast.error("Ruc o contraseña incorrectos", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        // let config = await getConfiguracion(); /////me falta toda la configuracion
        let data = {
          dNumTim: config.timbrado,
          dEst: values.desdeDoc.substring(0, 3),
          dPunExp: values.hastaDoc.substring(4, 7),
          dNumIn: values.desdeDoc.substring(8),
          dNumFin: values.hastaDoc.substring(8),
          iTiDE: values.comprobante,
          mOtEve: values.motivo,
        };

        let json = await invalidarDocs(data);
        if (json.estado === "Aprobado") {
          if (json.error === "") {
            toast.success("Documentos inutilizados con exito", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            handleClose();
          } else {
            toast.error(json.error, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        } else {
          toast.error(
            `${JSON.stringify(json.estado)} || ${JSON.stringify(
              json.msgRespuesta
            )}`,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      }
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Inutilización documento electrónico</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Con esta opción se inutiliza un rango de documentos electronicos.
            {values.comprobante}
          </DialogContentText>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl className="color" variant="standard" fullWidth>
                  <InputLabel id="demo-simple-select-standard-label">
                    Tipo de Documento
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    name="comprobante"
                    value={values.comprobante}
                    onChange={handleChange("comprobante")}
                  >
                    <MenuItem value="1">Factura</MenuItem>
                    <MenuItem value="5">Nota de crédito</MenuItem>
                    <MenuItem value="4">Autofactura</MenuItem>
                    <MenuItem value="6">Nota de débito</MenuItem>
                    <MenuItem value="7">Nota de remisión</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <UsrInput
                  name={"desdeDoc"}
                  handleChange={handleChange("desdeDoc")}
                  value={values.desdeDoc}
                  type={"text"}
                  label="Desde número de documento (###-###-#######)"
                  helperText={errores.desdeDoc}
                  error={errores.desdeDoc === "" ? false : true}
                  funBlur={funBlur}
                >
                  <DocumentScannerOutlinedIcon />
                </UsrInput>
              </Grid>
              <Grid item xs={12}>
                <UsrInput
                  name={"hastaDoc"}
                  handleChange={handleChange("hastaDoc")}
                  value={values.hastaDoc}
                  type={"text"}
                  label="Hasta número de documento (###-###-#######)"
                  helperText={errores.hastaDoc}
                  error={errores.hastaDoc === "" ? false : true}
                  funBlur={funBlur}
                >
                  <DocumentScannerOutlinedIcon />
                </UsrInput>
              </Grid>
              <Grid item xs={12}>
                <UsrInput
                  handleChange={handleChange("motivo")}
                  value={values.motivo}
                  type={"text"}
                  label="Motivo"
                  helperText={errores.motivo}
                  error={errores.motivo === "" ? false : true}
                >
                  <LiveHelpOutlinedIcon />
                </UsrInput>
              </Grid>
              <Divider variant="middle" />
              <Grid item xs={12}>
                <UsrInput
                  handleChange={handleChange("ruc")}
                  value={values.ruc}
                  type={"text"}
                  label="R.U.C."
                  helperText={errores.ruc}
                  error={errores.ruc === "" ? false : true}
                >
                  <PersonOutlinedIcon />
                </UsrInput>
              </Grid>
              <Grid item xs={12}>
                <PassInput
                  handleChange={handleChange}
                  values={values}
                  handleClickShowPassword={handleClickShowPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  helperText={errores.password}
                  error={errores.password === "" ? false : true}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>volver</Button>
          <Button onClick={handleClick}>Cancelar documento</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InutilizarDoc;
