import React, { useState } from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid } from "@mui/material";
import moment from "moment";

const VerDocs = (datos) => {
  const { open, handleClose, dataDE, handleConsultaLote } = datos;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"lg"}
      >
        <DialogTitle>Documentos Electrónicos en fase de Prueba</DialogTitle>
        <DialogContent>
          <Box>
            <Grid container>
              <Grid xs={3}>
                <h4>Tipo D.E.</h4>
              </Grid>
              <Grid xs={5}>
                <h4>C.D.C.</h4>
              </Grid>
              <Grid xs={1}>
                <h4>Fecha</h4>
              </Grid>
              <Grid xs={1} style={{ textAlign: "center" }}>
                <h4>Estado</h4>
              </Grid>
              <Grid xs={2} style={{ textAlign: "right" }}>
                <h4>Lote/Codigo</h4>
              </Grid>
              {dataDE &&
                dataDE.map((ite, index) => {
                  return (
                    <>
                      <Grid xs={3}>
                        <DialogContentText>{ite.tipoDe}</DialogContentText>
                      </Grid>
                      <Grid xs={5}>
                        <DialogContentText>{ite.cdc}</DialogContentText>
                      </Grid>
                      <Grid xs={1}>
                        <DialogContentText>
                          {moment(ite.fecha).format("DD/MM/YYYY")}
                        </DialogContentText>
                      </Grid>
                      <Grid xs={1}>
                        <DialogContentText style={{ textAlign: "center" }}>
                          {ite.estado}
                        </DialogContentText>
                      </Grid>
                      <Grid xs={2}>
                        <DialogContentText style={{ textAlign: "right" }}>
                          {ite.lote !== "" ? ite.lote : ite.codError}
                        </DialogContentText>
                      </Grid>
                    </>
                  );
                })}
              {dataDE && dataDE.length === 0 ? (
                <Grid xs={12}>
                  <DialogContentText style={{ textAlign: "center" }}>
                    SIN DATOS PARA MOSTRAR
                  </DialogContentText>
                </Grid>
              ) : null}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleConsultaLote}>
            Consultar Lote
          </Button>
          <Button onClick={handleClose} variant="outlined" color="error">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default VerDocs;
