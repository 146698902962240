import React from "react";

import '../../css/estilo.css';
import '../../css/login.css';
import Logo from "./Logo";
import Formulario from "./Formulario";
import Recupera from "./Recupera";
import { useNavigate } from "react-router-dom";
import { getSession } from "../../apis/Session";





const Login = () => {
  const navigate = useNavigate();
  let style = {
    borderRadius : '10px' ,
  }
  let padding2 = {
    padding : '1% 15%'
  }
  const [values, setValues] = React.useState({
    recupera: false,
  });

  const handleClickRecupera = () => {
    setValues({
      ...values,
      recupera: !values.recupera,
    });
  };

  React.useEffect(()=>{
    //clearSession()
    if(getSession()===null){
      navigate('../')
      // history.push("/")
    }
  },[])

  
    return(
      <div className="contenedor-usu">  
          <div className="contenedor caja-usu" style={style}>
            <Logo></Logo>
            {values.recupera?
              <Recupera handleClickRecupera={handleClickRecupera} padding2={padding2}/>:
              <Formulario handleClickRecupera={handleClickRecupera} padding2={padding2}/>}
          </div>
      </div>
    )
}

export default Login
