import React from "react";
import { Box, TextField } from "@mui/material";


const UsrInput = ({handleChange, value, label, type, helperText, error, funBlur, name, children}) => {
 
  return (
    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
    {children}
    <TextField name={name} type={type} label={label} variant="standard" fullWidth value={value} style={{marginLeft:'5px'}}
        onChange={handleChange}
        helperText={helperText}
        error={error}
        onBlur={funBlur}
        />
    </Box>
  )  
} 

export default UsrInput