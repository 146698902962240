import React from "react";
import Menu from "./menu/Menu";
import { Box } from "@mui/material";
import "../../css/header.css";
import GrillaDoc from "./GrillaDoc";

const Principal = () => {
  const style = {
    width: "80%",
    height: "calc(100vh)",
  };

  return (
    <div>
      <Box sx={{ display: "flex" }} overflow="hidden">
        <Menu />
        <div style={style}>
          <GrillaDoc />
        </div>
      </Box>
    </div>
  );
};

export default Principal;
