import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { BugReportOutlined } from "@mui/icons-material";
import { AssessmentOutlined } from "@mui/icons-material";
import Menuitem from "./Menuitem";
import { clearSession } from "../../../apis/Session";
import { SessionContext } from "../../../hook/SessionContext";

const Menulist = (server) => {
  const navigate = useNavigate();
  const { updSession } = useContext(SessionContext);

  const cerrarSesion = () => {
    clearSession();
    updSession("");
    navigate("../");
  };

  const documentos = () => {
    navigate("../menu");
  };

  const reportes = () => {
    navigate("../reportes");
  };

  const configuracion = () => {
    navigate("../configuracion");
  };

  const pruebas = () => {
    navigate("../pruebas");
  };

  /////menuitems

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const consultaDE = () => {
    navigate("../consultaDE");
  };

  const consultaLote = () => {
    navigate("../consultaLote");
  };

  ///////menuitems

  return (
    <>
      {localStorage.getItem("server").match(/PRUEBAS.*/) ? (
        <MenuList>
          {/* <Menuitem text="Inicio">
          <HomeOutlinedIcon fontSize="small" />
        </Menuitem> */}
          <Menuitem handleClick={documentos} text="Documentos">
            <ArticleOutlinedIcon fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={reportes} text="Reportes">
            <AssessmentOutlined fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={pruebas} text="Etapa de Pruebas SIFEN">
            <BugReportOutlined fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={configuracion} text="Configuracion">
            <SettingsOutlinedIcon fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={cerrarSesion} text="Cerrar Sesión">
            <LogoutOutlinedIcon fontSize="small" />
          </Menuitem>
        </MenuList>
      ) : (
        <MenuList>
          {/* <Menuitem text="Inicio">
          <HomeOutlinedIcon fontSize="small" />
        </Menuitem> */}
          <Menuitem handleClick={documentos} text="Documentos">
            <ArticleOutlinedIcon fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={reportes} text="Reportes">
            <AssessmentOutlined fontSize="small" />
          </Menuitem>
          {/* <Menuitem text="Eventos" handleClick={handleClick}>
          <EventAvailableOutlinedIcon fontSize="small" />
        </Menuitem> */}
          {/* <Menu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <Menuitem handleClick={consultaDE} onClick={handleClose} disableRipple>
            Consultas DE
          </Menuitem>
          <Menuitem
            handleClick={consultaLote}
            onClick={handleClose}
            disableRipple
          >
            Consultas Lote
          </Menuitem>
        </Menu> */}
          <Menuitem handleClick={configuracion} text="Configuracion">
            <SettingsOutlinedIcon fontSize="small" />
          </Menuitem>
          <Menuitem handleClick={cerrarSesion} text="Cerrar Sesión">
            <LogoutOutlinedIcon fontSize="small" />
          </Menuitem>
        </MenuList>
      )}
    </>
  );
};

export default Menulist;
