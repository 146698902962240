const dataDE_ = {
  Id: "01800260414001001001029612022103116012575984",
  dDVId: 4,
  dFecFirma: "",
  dSisFact: 0,
  gOpeDE: {
    iTipEmi: 1,
    dDesTipEmi: "Normal",
    dCodSeg: 601257598,
    dInfoEmi: "",
    dInfoFisc: "",
  },
  gTimb: {
    iTiDE: 1,
    dDesTiDE: "Factura electrónica",
    dNumTim: 15181564,
    dEst: "001",
    dPunExp: "001",
    dNumDoc: "0010296",
    dSerieNum: "",
    dFeIniT: "2021-10-20",
  },
  gDatGralOpe: {
    dFeEmiDE: "2022-10-31T11:26:10",
    gOpeCom: {
      iTipTra: 2,
      dDesTipTra: "Prestación de servicios",
      iTImp: 5,
      dDesTImp: "IVA – Renta",
      cMoneOpe: "PYG",
      dDesMoneOpe: "Guarani",
      dCondTiCam: "",
      dTiCam: "",
      iCondAnt: 1,
      dDesCondAnt: "Anticipo Global",
    },
    gEmis: {
      dRucEm: "80026041",
      dDVEmi: 4,
      iTipCont: 1,
      cTipReg: 8,
      dNomEmi: "Starsoft S.R.L.",
      dNomFanEmi: "Starsoft S.R.L.",
      dDirEmi: "Independencia Nacional",
      dNumCas: 802,
      dCompDir1: "Humaita",
      dCompDir2: "",
      cDepEmi: "1",
      dDesDepEmi: "CAPITAL",
      cDisEmi: "1",
      dDesDisEmi: "ASUNCION (DISTRITO)",
      cCiuEmi: "1",
      dDesCiuEmi: "ASUNCION (DISTRITO)",
      dTelEmi: "021492888",
      dEmailE: "lsamudio@starsoft.com.py",
      dDenSuc: "Casa Central",
      gActEco: [
        {
          cActEco: "62010",
          dDesActEco: "ACTIVIDADES DE PROGRAMACIÓN INFORMÁTICA",
        },
      ],
      gRespDE: {
        iTipIDRespDE: 1,
        dDTipIDRespDE: "Cédula paraguaya",
        dNumIDRespDE: "4993811",
        dNomRespDE: "Viviana Elizabeth Benitez Salinas",
        dCarRespDE: "Administrador",
      },
    },
    gDatRec: {
      iNatRec: 1,
      iTiOpe: 2,
      cPaisRec: "PRY",
      dDesPaisRe: "Paraguay",
      iTiContRec: 2,
      dRucRec: "80026041",
      dDVRec: 4,
      dDTipIDRec: "Cédula paraguaya",
      dNumIDRec: "0",
      dNomRec: "Starsoft S.R.L.",
      dNomFanRec: "Starsoft S.R.L.",
      dDirRec: "Independencia Nacional Nº802",
      dNumCasRec: "802",
      cDepRec: "1",
      dDesDepRec: "CAPITAL",
      cDisRec: "1",
      dDesDisRec: "ASUNCION (DISTRITO)",
      cCiuRec: "1",
      dDesCiuRec: "ASUNCION (DISTRITO)",
      dTelRec: "0991XXXXXX",
      dCelRec: "0991XXXXXX",
      dEmailRec: "lsamudio@starsoft.com.py",
      dCodCliente: "80026041-4",
    },
  },
  gDtipDE: {
    gCamFE: {
      iIndPres: 1,
      dDesIndPres: "Operación presencial",
      dFecEmNR: "2022-10-31",
      gCompPub: {
        dModCont: "",
        dEntCont: 0,
        dAnoCont: 0,
        dSecCont: 0,
        dFeCodCont: "",
      },
    },
    gCamAE: {
      iNatVen: 0,
      dDesNatVen: "",
      iTipIDVen: 0,
      dDTipIDVen: "",
      dNumIDVen: "",
      dNomVen: "",
      dDirVen: "",
      dNumCasVen: 0,
      cDepVen: 0,
      dDesDepVen: "",
      cDisVen: 0,
      dDesDisVen: "",
      cCiuVen: 0,
      dDesCiuVen: "",
      dDirProv: "",
      cDepProv: 0,
      dDesDepProv: "",
      cDisProv: 0,
      dDesDisProv: "",
      cCiuProv: 0,
      dDesCiuProv: "",
    },
    gCamNCDE: {
      iMotEmi: "",
      dDesMotEmi: "",
    },
    gCamNRE: {
      iMotEmiNR: 0,
      dDesMotEmiNR: "",
      iRespEmiNR: 0,
      dDesRespEmiNR: "",
      dKmR: 0,
      dFecEm: "",
    },
    gCamCond: {
      iCondOpe: 2,
      dDCondOpe: "Credito",
      gPaConEIni: [
        {
          iTiPago: 1,
          dDesTiPag: "Efectivo",
          dMonTiPag: 0,
          cMoneTiPag: "PYG",
          dDMoneTiPag: "Guarani",
          dTiCamTiPag: "",
          gPagTarCD: {
            iDenTarj: 0,
            dDesDenTarj: "",
            dRSProTar: "",
            dRUCProTar: "",
            dDVProTar: "",
            iForProPa: 1,
            dCodAuOpe: 0,
            dNomTit: "",
            dNumTarj: 0,
          },
          gPagCheq: {
            dNumCheq: "0",
            dBcoEmi: "",
          },
        },
      ],
      gPagCred: {
        iCondCred: 2,
        dDCondCred: "Cuota",
        dPlazoCre: "",
        dCuotas: 1,
        dMonEnt: "0",
        gCuotas: [
          {
            cMoneCuo: "PYG",
            dDMoneCuo: "Guarani",
            dMonCuota: 500000.0,
            dVencCuo: "2022-11-30",
          },
        ],
      },
    },
    gCamItem: [
      {
        dCodInt: "000001",
        dParAranc: "",
        dNCM: "",
        dDncpG: "",
        dDncpE: "",
        dGtin: "",
        dGtinPq: "",
        dDesProSer: "SERVICIO GRAVADO A",
        cUniMed: 77,
        dDesUniMed: "UNI",
        dCantProSer: 1.0,
        cPaisOrig: "PRY",
        dDesPaisOrig: "Paraguay",
        dInfItem: "",
        cRelMerc: "",
        dDesRelMerc: "",
        dCanQuiMer: 0,
        dPorQuiMer: 0,
        dCDCAnticipo: "",
        gValorItem: {
          dPUniProSer: 250000.0,
          dTiCamIt: 0,
          dTotBruOpeItem: 250000.0,
          gValorRestaItem: {
            dDescItem: 0.0,
            dPorcDesIt: 0.0,
            dDescGloItem: 0,
            dAntPreUniIt: 0,
            dAntGloPreUniIt: 0,
            dTotOpeItem: 250000.0,
            dTotOpeGs: 0,
          },
        },
        gCamIVA: {
          iAfecIVA: 1,
          dDesAfecIVA: "Gravado IVA",
          dPropIVA: 100,
          dTasaIVA: 10,
          dBasGravIVA: 227272.72727273,
          // dBasGravIVA: [100 * 250000 * 100] / [10000 + 10 * 100],
          dLiqIVAItem: 22727.27272727,
          dBasExe: 0,
        },
        gRasMerc: {},
        gVehNuevo: {
          iTipOpVN: 0,
          dDesTipOpVN: "",
          dChasis: "",
          dColor: "",
          dPotencia: 0,
          dCapMot: 0,
          dPNet: 0,
          dPBruto: 0,
          iTipCom: 0,
          dDesTipCom: "",
          dNroMotor: "",
          dCapTracc: 0,
          dAnoFab: 0,
          cTipVeh: "",
          dCapac: 0,
          dCilin: "",
        },
      },
      {
        dCodInt: "000002",
        dParAranc: "",
        dNCM: "",
        dDncpG: "",
        dDncpE: "",
        dGtin: "",
        dGtinPq: "",
        dDesProSer: "SERVICIO GRAVADO B",
        cUniMed: 77,
        dDesUniMed: "UNI",
        dCantProSer: 1.0,
        cPaisOrig: "PRY",
        dDesPaisOrig: "Paraguay",
        dInfItem: "",
        cRelMerc: "",
        dDesRelMerc: "",
        dCanQuiMer: 0,
        dPorQuiMer: 0,
        dCDCAnticipo: "",
        gValorItem: {
          dPUniProSer: 250000.0,
          dTiCamIt: 0,
          dTotBruOpeItem: 250000.0,
          gValorRestaItem: {
            dDescItem: 0.0,
            dPorcDesIt: 0.0,
            dDescGloItem: 0,
            dAntPreUniIt: 0,
            dAntGloPreUniIt: 0,
            dTotOpeItem: 250000.0,
            dTotOpeGs: 0,
          },
        },
        gCamIVA: {
          iAfecIVA: 1,
          dDesAfecIVA: "Gravado IVA",
          dPropIVA: 100,
          dTasaIVA: 10,
          dBasGravIVA: 227272.72727273,
          // dBasGravIVA: [100 * 250000 * 100] / [10000 + 10 * 100],
          dLiqIVAItem: 22727.27272727,
          dBasExe: 0,
        },
        gRasMerc: {},
        gVehNuevo: {
          iTipOpVN: 0,
          dDesTipOpVN: "",
          dChasis: "",
          dColor: "",
          dPotencia: 0,
          dCapMot: 0,
          dPNet: 0,
          dPBruto: 0,
          iTipCom: 0,
          dDesTipCom: "",
          dNroMotor: "",
          dCapTracc: 0,
          dAnoFab: 0,
          cTipVeh: "",
          dCapac: 0,
          dCilin: "",
        },
      },
    ],
    gCamEsp: {
      gGrupEner: {
        dNroMed: 0,
        dActiv: 0,
        dCateg: 0,
        dLecAnt: 0,
        dLecAct: 0,
        dConKwh: 0,
      },
      gGrupSeg: {},
    },
    gTransp: {},
  },
  gTotSub: {
    dSubExe: 0.0,
    dSubExo: 0,
    dSub5: 0.0,
    dSub10: 500000.0,
    dTotOpe: 500000.0,
    dTotDesc: 0.0,
    dTotDescGlotem: 0,
    dTotAntItem: 0,
    dTotAnt: 0,
    dPorcDescTotal: 0,
    dDescTotal: 0.0,
    dAnticipo: 0,
    dRedon: 0,
    dComi: 0,
    dTotGralOpe: 500000.0,
    dIVA5: 0.0,
    dIVA10: 45454.54545454,
    dLiqTotIVA5: 0,
    dLiqTotIVA10: 0,
    dIVAComi: 0,
    dTotIVA: 45454.54545454,
    dBaseGrav5: 0.0,
    dBaseGrav10: 454545.45454546,
    dTBasGraIVA: 454545.45454546,
    dTotalGs: 0,
  },
  gCamGen: {
    dOrdCompra: "",
    dOrdVta: "",
    dAsiento: "",
    gCamCarg: {
      cUniMedTotVol: 0,
      dDesUniMedTotVol: "",
      dTotVolMerc: 0,
      cUniMedTotPes: 0,
      dDesUniMedTotPes: "",
      dTotPesMerc: 0,
      iCarCarga: 0,
      dDesCarCarga: "",
    },
  },
  gCamDEAsoc: {
    iTipDocAso: null,
    dDesTipDocAso: null,
    dCdCDERef: null,
  },
};

const dataAU_ = {
  Id: "04800260619001001000005112022101216089147131",
  dDVId: 1,
  dFecFirma: "",
  dSisFact: 0,
  gOpeDE: {
    iTipEmi: 1,
    dDesTipEmi: "Normal",
    dCodSeg: 608914713,
    dInfoEmi: "",
    dInfoFisc: "",
  },
  gTimb: {
    iTiDE: 4,
    dDesTiDE: "Autofactura electrónica",
    dNumTim: 12559856,
    dEst: "001",
    dPunExp: "001",
    dNumDoc: "0000051",
    dSerieNum: "",
    dFeIniT: "2022-09-19",
  },
  gDatGralOpe: {
    dFeEmiDE: "2022-10-12T08:38:01",
    gOpeCom: {
      iTipTra: 2,
      dDesTipTra: "Prestación de servicios",
      iTImp: 1,
      dDesTImp: "IVA",
      cMoneOpe: "PYG",
      dDesMoneOpe: "Guarani",
      dCondTiCam: "",
      dTiCam: "",
      iCondAnt: 1,
      dDesCondAnt: "Anticipo Global",
    },
    gEmis: {
      dRucEm: "80026061",
      dDVEmi: 9,
      iTipCont: 1,
      cTipReg: 8,
      dNomEmi:
        "DE generado en ambiente de prueba - sin valor comercial ni fiscal",
      dNomFanEmi: "",
      dDirEmi: "CALLE, CRUZ DEL DEFENSOR C/ CAPOA CERVERA NUMERO #473",
      dNumCas: 473,
      dCompDir1: "",
      dCompDir2: "",
      cDepEmi: "1",
      dDesDepEmi: "CAPITAL",
      cDisEmi: "1",
      dDesDisEmi: "ASUNCION (DISTRITO)",
      cCiuEmi: "1",
      dDesCiuEmi: "ASUNCION (DISTRITO)",
      dTelEmi: "021608423",
      dEmailE: "secretaria@btrtransportes.com.br",
      dDenSuc: "",
      gActEco: [
        {
          cActEco: "52299",
          dDesActEco:
            "OPERACIÓN DE MANIPULACIÓN DE PRODUCTOS Y OTRAS ACTIVIDADES COMPLEMENTARIAS AL TRANSPORTE N.C.P.",
        },
      ],
      gRespDE: {
        iTipIDRespDE: 1,
        dDTipIDRespDE: "Cédula paraguaya",
        dNumIDRespDE: "3900782",
        dNomRespDE: "CARLOS A. BENITEZ",
        dCarRespDE: "REPRESENTANTE LEGAL",
      },
    },
    gDatRec: {
      iNatRec: 1,
      iTiOpe: 2,
      cPaisRec: "PRY",
      dDesPaisRe: "Paraguay",
      iTiContRec: 1,
      dRucRec: 4943905,
      dDVRec: 0,
      iTipIDRec: 1,
      dDTipIDRec: "Cédula paraguaya",
      dNumIDRec: "4943905",
      dNomRec: "GUSTAVO GABRIEL GONZALEZ GIMENEZ",
      dDirRec: "",
      dNomFanRec: "",
      dTelRec: "0974902652",
      dCelRec: "",
      dEmailRec: "",
      dCodCliente: "4943905",
    },
  },
  gDtipDE: {
    gCamFE: {
      iIndPres: "",
      dDesIndPres: "",
      dFecEmNR: "",
      gCompPub: {
        dModCont: "",
        dEntCont: 0,
        dAnoCont: 0,
        dSecCont: 0,
        dFeCodCont: "",
      },
    },
    gCamAE: {
      iNatVen: 1,
      dDesNatVen: "No contribuyente",
      iTipIDVen: 1,
      dDTipIDVen: "Cédula paraguaya",
      dNumIDVen: "4943905",
      dNomVen: "GUSTAVO GABRIEL GONZALEZ GIMENEZ",
      dDirVen: "ASUNCION",
      dNumCasVen: 0,
      cDepVen: 1,
      dDesDepVen: "CAPITAL",
      cDisVen: 1,
      dDesDisVen: "ASUNCION (DISTRITO)",
      cCiuVen: 1,
      dDesCiuVen: "ASUNCION (DISTRITO)",
      dDirProv: "CENTRAL",
      cDepProv: 1,
      dDesDepProv: "CAPITAL",
      cDisProv: 1,
      dDesDisProv: "ASUNCION (DISTRITO)",
      cCiuProv: 1,
      dDesCiuProv: "ASUNCION (DISTRITO)",
    },
    gCamNCDE: {
      iMotEmi: "",
      dDesMotEmi: "",
    },
    gCamNRE: {
      iMotEmiNR: 0,
      dDesMotEmiNR: "",
      iRespEmiNR: 0,
      dDesRespEmiNR: "",
      dKmR: 0,
      dFecEm: "",
    },
    gCamCond: {
      iCondOpe: 1,
      dDCondOpe: "Contado",
      gPaConEIni: [
        {
          iTiPago: 1,
          dDesTiPag: "Efectivo",
          dMonTiPag: 450000.0,
          cMoneTiPag: "PYG",
          dDMoneTiPag: "Guarani",
          dTiCamTiPag: "",
          gPagTarCD: {
            iDenTarj: 0,
            dDesDenTarj: "",
            dRSProTar: "",
            dRUCProTar: "",
            dDVProTar: "",
            iForProPa: 1,
            dCodAuOpe: 0,
            dNomTit: "",
            dNumTarj: 0,
          },
          gPagCheq: {
            dNumCheq: "0",
            dBcoEmi: "",
          },
        },
      ],
      gPagCred: {
        iCondCred: "",
        dDCondCred: "",
        dPlazoCre: "",
        dCuotas: 0,
        dMonEnt: "0",
      },
    },
    gCamItem: [
      {
        dCodInt: "12345",
        dParAranc: "",
        dNCM: "",
        dDncpG: "",
        dDncpE: "",
        dGtin: "",
        dGtinPq: "",
        dDesProSer: "SERVICIOS VARIOS",
        cUniMed: 77,
        dDesUniMed: "UNI",
        dCantProSer: 1.0,
        cPaisOrig: "PRY",
        dDesPaisOrig: "Paraguay",
        dInfItem: "",
        cRelMerc: "",
        dDesRelMerc: "",
        dCanQuiMer: 0,
        dPorQuiMer: 0,
        dCDCAnticipo: "",
        gValorItem: {
          dPUniProSer: 450000.0,
          dTiCamIt: 0,
          dTotBruOpeItem: 450000.0,
          gValorRestaItem: {
            dDescItem: 0.0,
            dPorcDesIt: 0.0,
            dDescGloItem: 0,
            dAntPreUniIt: 0,
            dAntGloPreUniIt: 0,
            dTotOpeItem: 450000.0,
            dTotOpeGs: 0,
          },
        },
        gRasMerc: {},
        gVehNuevo: {
          iTipOpVN: 0,
          dDesTipOpVN: "",
          dChasis: "",
          dColor: "",
          dPotencia: 0,
          dCapMot: 0,
          dPNet: 0,
          dPBruto: 0,
          iTipCom: 0,
          dDesTipCom: "",
          dNroMotor: "",
          dCapTracc: 0,
          dAnoFab: 0,
          cTipVeh: "",
          dCapac: 0,
          dCilin: "",
        },
      },
      {
        dCodInt: "54321",
        dParAranc: "",
        dNCM: "",
        dDncpG: "",
        dDncpE: "",
        dGtin: "",
        dGtinPq: "",
        dDesProSer: "SERVICIOS VARIOS B",
        cUniMed: 77,
        dDesUniMed: "UNI",
        dCantProSer: 1.0,
        cPaisOrig: "PRY",
        dDesPaisOrig: "Paraguay",
        dInfItem: "",
        cRelMerc: "",
        dDesRelMerc: "",
        dCanQuiMer: 0,
        dPorQuiMer: 0,
        dCDCAnticipo: "",
        gValorItem: {
          dPUniProSer: 450000.0,
          dTiCamIt: 0,
          dTotBruOpeItem: 450000.0,
          gValorRestaItem: {
            dDescItem: 0.0,
            dPorcDesIt: 0.0,
            dDescGloItem: 0,
            dAntPreUniIt: 0,
            dAntGloPreUniIt: 0,
            dTotOpeItem: 450000.0,
            dTotOpeGs: 0,
          },
        },
        gRasMerc: {},
        gVehNuevo: {
          iTipOpVN: 0,
          dDesTipOpVN: "",
          dChasis: "",
          dColor: "",
          dPotencia: 0,
          dCapMot: 0,
          dPNet: 0,
          dPBruto: 0,
          iTipCom: 0,
          dDesTipCom: "",
          dNroMotor: "",
          dCapTracc: 0,
          dAnoFab: 0,
          cTipVeh: "",
          dCapac: 0,
          dCilin: "",
        },
      },
    ],
    gCamEsp: {
      gGrupEner: {
        dNroMed: 0,
        dActiv: 0,
        dCateg: 0,
        dLecAnt: 0,
        dLecAct: 0,
        dConKwh: 0,
      },
      gGrupSeg: {},
    },
    gTransp: {},
  },
  gTotSub: {
    dTotOpe: 900000.0,
    dTotDesc: 0.0,
    dTotDescGlotem: 0,
    dTotAntItem: 0,
    dTotAnt: 0,
    dPorcDescTotal: 0,
    dDescTotal: 0.0,
    dAnticipo: 0,
    dRedon: 0,
    dTotGralOpe: 900000.0,
  },
  gCamGen: {
    dOrdCompra: "",
    dOrdVta: "",
    dAsiento: "",
    gCamCarg: {
      cUniMedTotVol: 0,
      dDesUniMedTotVol: "",
      dTotVolMerc: 0,
      cUniMedTotPes: 0,
      dDesUniMedTotPes: "",
      dTotPesMerc: 0,
      iCarCarga: 0,
      dDesCarCarga: "",
    },
  },
  gCamDEAsoc: {
    iTipDocAso: 3,
    dDesTipDocAso: "Constancia Electrónica",
    iTipCons: 1,
    dDesTipCons: "Constancia de no ser contribuyente",
    dNumCons: 0,
    dNumControl: "",
  },
};

const inuparam = {
  dNumTim: 0,
  dEst: "",
  dPunExp: "",
  dNumIn: "",
  dNumFin: "",
  iTiDE: 0,
  mOtEve: "Inutilización de documentos fase de prueba",
};

const idesconoceparam = {
  Id: "",
  dFecEmi: "",
  dFecRecep: "",
  iTipRec: 1,
  dNomRec: "",
  dRucRec: "",
  dDVRec: 0,
  dTipIDRec: null,
  dNumID: null,
  mOtEve: "Desconocimiento de documentos fase de prueba",
};

const notifyparam = {
  Id: "",
  dFecEmi: "",
  dFecRecep: "",
  iTipRec: 1,
  dNomRec: "",
  dRucRec: "",
  dDVRec: 0,
  dTipIDRec: null,
  dNumID: null,
  dTotalGs: null,
};

export { dataDE_, dataAU_, inuparam, idesconoceparam, notifyparam };
