export const tipoDoc = [
  { label: "Factura", value: "FC", key: 0 },
  { label: "Nota de Crédito", value: "NC", key: 1 },
  { label: "Nota de Remisión", value: "NR", key: 3 },
  { label: "Nota de Débito", value: "ND", key: 2 },
  { label: "Autofactura", value: "AU", key: 4 },
  { label: "Factura Exportación", value: "EXP", key: 5 },
];

export const establecimiento = [
  { label: "001", value: "001", key: 0 },
  { label: "002", value: "002", key: 1 },
  { label: "003", value: "003", key: 2 },
  { label: "004", value: "004", key: 3 },
  { label: "005", value: "005", key: 4 },
  { label: "006", value: "006", key: 5 },
  { label: "007", value: "007", key: 6 },
  { label: "008", value: "008", key: 7 },
  { label: "009", value: "009", key: 8 },
  { label: "010", value: "010", key: 9 },
  { label: "011", value: "011", key: 10 },
  { label: "012", value: "012", key: 11 },
  { label: "013", value: "013", key: 12 },
  { label: "014", value: "014", key: 13 },
  { label: "015", value: "015", key: 14 },
];

export const expedicion = [
  { label: "001", value: "001", key: 0 },
  { label: "002", value: "002", key: 1 },
  { label: "003", value: "003", key: 2 },
  { label: "004", value: "004", key: 3 },
  { label: "005", value: "005", key: 4 },
  { label: "006", value: "006", key: 5 },
  { label: "007", value: "007", key: 6 },
  { label: "008", value: "008", key: 7 },
  { label: "009", value: "009", key: 8 },
  { label: "010", value: "010", key: 9 },
  { label: "011", value: "011", key: 10 },
  { label: "012", value: "012", key: 11 },
  { label: "013", value: "013", key: 12 },
  { label: "014", value: "014", key: 13 },
  { label: "015", value: "015", key: 14 },
];
